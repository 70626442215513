import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>© 2024 Taelo Seholoba. All rights reserved.</p>
      <div>
        <a target='_blank'  rel="noreferrer" href="https://github.com/Taelo1829" >GitHub</a>
        <a target='_blank'  rel="noreferrer" href="https://www.linkedin.com/in/taelo-seholoba-92803b191/">LinkedIn</a>
        <a target='_blank'  rel="noreferrer" href="https://wa.me/0742019041">Whatsapp</a>
        <a target='_blank'  rel="noreferrer" href="mailto:tseholoba2@gmail.com">Gmail</a>
      </div>
    </footer>
  );
};

export default Footer;
