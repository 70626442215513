import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
const Home = () => {
    return (
        <section className="home">
            <div className="hero">
                <h1>Hello, I'm Taelo</h1>
                <p className="subtitle">A Passionate Junior Software Developer</p>
                <p className="intro">
                    I specialize in building modern web applications using technologies like React, Node.js, .NET, and Express.js.
                    With a keen eye for detail and a love for problem-solving, I am excited to create solutions that make a difference.
                </p>
                <Link to="/projects" className="cta-button">See My Work</Link>
            </div>
            <div className="bio">
                <img src="/assets/profile.jpeg" alt="Profile" className="profile-img" />
                <div className="bio-text">
                    <h2>About Me</h2>
                    <p>
                        I'm a dedicated software developer with a background in creating efficient and scalable web solutions.
                        Whether it's designing intuitive interfaces or developing robust back-end systems, I strive to deliver high-quality work.
                        When I'm not coding, you can find me exploring new technologies, reading tech blogs, or working on personal projects.
                    </p>
                    <Link to="/contact" className="cta-button">Get In Touch</Link>
                </div>
            </div>
        </section>
    );
};

export default Home;