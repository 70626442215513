// src/pages/Projects.js
import React from 'react';
import './Projects.css'; // Import the CSS for the Projects page

const projects = [
    {
        title: 'Project One',
        description: 'A brief description of Project One. This could include the technologies used and the main features of the project.',
        image: '/assets/project1.jpg', // Add paths to your project images
        link: 'https://github.com/yourusername/project-one'
    },
    {
        title: 'Project Two',
        description: 'A brief description of Project Two. Highlight key aspects of the project and what makes it stand out.',
        image: '/assets/project2.jpg',
        link: 'https://github.com/yourusername/project-two'
    },
    // Add more projects as needed
];

const Projects = () => {
    return (
        <section className="projects">
            <h1>My Projects</h1>
            <div className="projects-container">
                {projects.map((project, index) => (
                    <div key={index} className="project-card">
                        <img src={project.image} alt={project.title} className="project-image" />
                        <div className="project-content">
                            <h2>{project.title}</h2>
                            <p>{project.description}</p>
                            <a href={project.link} target="_blank" rel="noopener noreferrer" className="cta-button">View on GitHub</a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Projects;
